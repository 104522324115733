const streamFeatures = [
  {
    title: 'instreamSwitching.section4.list1.item1.title',
    content: 'instreamSwitching.section4.list1.item1.content'
  },
  {
    title: 'instreamSwitching.section4.list1.item2.title',
    content: 'instreamSwitching.section4.list1.item2.content'
  },
  {
    title: 'instreamSwitching.section4.list1.item3.title',
    content: 'instreamSwitching.section4.list1.item3.content'
  },
  {
    title: 'instreamSwitching.section4.list1.item4.title',
    content: 'instreamSwitching.section4.list1.item4.content'
  },
  {
    title: 'instreamSwitching.section4.list1.item5.title',
    content: 'instreamSwitching.section4.list1.item5.content'
  }
]

export { streamFeatures }
